<template>
    <div class="favorites-slider">
        <div class="inSalabamMonographicSlider">
            <Spinner v-if="loading" />
            <div v-else class="postSlider">
                <div class="postSlider" v-if="!loading">
                    <div class="sliderHeading">
                        <div class="sliderHeading--icon">
                            <font-awesome-icon icon="heart"/>
                        </div>
                        <div class="sliderHeading--title">
                            <strong>I tuoi preferiti</strong>
                            <br>
                            <span class="d-flex align-items-center flex-wrap">
                                <span v-if="favorites.length == 0" class="history-slider__message">
                                    Non hai ancora aggiunto preferiti
                                </span>
                                <span v-else>Ecco le tue strutture preferite. Controlla offerte e disponibilità in tempo reale!</span>
                            </span>
                        </div>
                    </div>
                    <!-- versione mobile: pad informativa inserita tra le slides -->
                    <div v-if="favorites.length > 0" class="slick mobile">
                        <Slick :ref="_uid+'_slick'" :options="slickOptionsMobile" @init="handleInit">

                        <div class="property_pad" v-for="(favorite, index) in (favorites.length > 3 ? (favorites.slice(0,2)): favorites)" :key="'slide-m-'+index" :data-property_id="favorite.property.propertyId">
                            <div class="postPad" @click="goToProperty(favorite)">
                                <div class="postPad__thumbnail" :style="'background-image:url('+favorite.property.image+');'"></div>
                                    <div class="postPad__content">
                                        <div>
                                        <div class="postPad__title d-flex">
                                            <SvgOccupancy />
                                            <span>{{favorite.property.name}}</span>
                                        </div>
                                        <div class="property-pad__city">
                                            <SvgPin />
                                            {{favorite.property.address}}, {{favorite.property.city}}
                                        </div>
                                        <div class="property__amenities">
                                            <span v-for="(value,index) in (Object.keys(favorite.property.amenities))" v-bind:key="value">
                                                <span :class="'amenity '+(icons['propertyAmenities'][value] !== undefined ? icons['propertyAmenities'][value] : 'icon-default')" v-if="amenitiesLabels[value] && index < 2">
                                                    {{amenitiesLabels[value].label}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="infoPad-wrapper">
                            <div class="infoPad">
                                <div class="background">
                                    <h5 class="title">
                                        <font-awesome-icon icon="eye"/>
                                        I tuoi preferiti
                                    </h5>
                                    <p class="abstract">
                                        Scopri con un click tutte le disponibilità e i prezzi delle tue strutture preferite
                                    </p>
                                    <span class="btn link" @click="searchLite()">Scopri le disponibilità</span>
                                </div>
                            </div>
                        </div>

                        <div class="property_pad" v-for="(favorite,index) in (favorites.length > 3 ? (favorites.slice(2,favorites.length)) : favorites)" :key="'slide-m-'+(index+3)" :data-property_id="favorite.property.propertyId">
                            <div class="postPad" @click="goToProperty(favorite)">
                                <div class="postPad__thumbnail" :style="'background-image:url('+favorite.property.image+');'"></div>
                                    <div class="postPad__content">
                                        <div class="propertyRate">
                                        <div class="postPad__title d-flex">
                                            <SvgOccupancy />
                                            <span>{{favorite.property.name}}</span>
                                        </div>
                                        <div class="property-pad__city">
                                            <SvgPin />
                                            {{favorite.property.address}}, {{favorite.property.city}}
                                        </div>
                                        <div class="property__amenities">
                                            <span v-for="(value,index) in (Object.keys(favorite.property.amenities))" v-bind:key="value">
                                                <span :class="'amenity '+(icons['propertyAmenities'][value] !== undefined ? icons['propertyAmenities'][value] : 'icon-default')"
                                                        v-if="index < 2">
                                                    {{amenitiesLabels[value].label}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        </Slick>

                        <span class="slick-arrow slick-prev" :id="'slick-prev-inSalabam'+_uid+'_m'">
                            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                            <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>

                        <span class="slick-arrow slick-next" :id="'slick-next-inSalabam'+_uid+'_m'">
                            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                            <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                    </div>
                    <!-- versione desktop: pad informativa fissa a destra -->
                    <div v-if="favorites.length > 0" class="slick desktop">
                        <div class="slick-wrapper">

                            <Slick :ref="_uid+'_slick'" :options="slickOptionsDesktop" @init="handleInit">

                            <div class="property_pad" v-for="(favorite, index) in favorites" :key="'slide-'+(index+2)" :data-property_id="favorite.property.propertyId">
                                <div class="postPad" @click="goToProperty(favorite)">
                                <div class="postPad__thumbnail" :style="'background-image:url('+favorite.property.image+');'"></div>
                                    <div class="postPad__content">
                                        <div class="propertyRate">
                                            <div class="postPad__title d-flex">
                                                <SvgOccupancy />
                                                <span>{{favorite.property.name}}</span>
                                            </div>
                                            <div class="property-pad__city">
                                                <SvgPin />
                                                {{favorite.property.address}}, {{favorite.property.city}}
                                            </div>
                                            <div class="property__amenities">
                                                <span v-for="(value,index) in (Object.keys(favorite.property.amenities))" v-bind:key="value">
                                                    <span :class="'amenity '+(icons['propertyAmenities'][value] !== undefined ? icons['propertyAmenities'][value] : 'icon-default')"
                                                            v-if="index < 2">
                                                        {{amenitiesLabels[value].label}}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            </Slick>

                            <span class="slick-arrow slick-prev" :id="'slick-prev-inSalabam'+_uid+'_d'">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                                <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>

                            <span class="slick-arrow slick-next" :id="'slick-next-inSalabam'+_uid+'_d'">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                                <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>

                        </div>

                        <div class="infoPad-wrapper">
                            <div class="infoPad">
                                <div class="background">
                                    <h5 class="title">
                                        <font-awesome-icon icon="eye"/>
                                        I tuoi preferiti
                                    </h5>
                                    <p class="abstract">
                                      Ecco le tue strutture preferite. Controlla offerte e disponibilità in tempo reale!
                                    </p>
                                    <span class="btn link" @click="searchLite()">Scopri le disponibilità</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Spinner from '../../atoms/spinner'
import Slick from 'vue-slick'
import SvgOccupancy from '../../svg/occupancy'
import SvgPin from '../../svg/pin'
import User from '@/user'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHeart, faSearch } from '@fortawesome/free-solid-svg-icons'
library.add(faHeart)
library.add(faSearch)
Vue.component('font-awesome-icon', FontAwesomeIcon)

export default {
    name: 'favorites-slider',
    props:{
    },
    components:{
        Spinner,
        Slick,
        SvgOccupancy,
        SvgPin,
    },
    data()
    {
        return {
            icons: Vue.prototype.$config.icons,
            amenitiesLabels:    Vue.prototype.$config.amenities,
            loading: true,
            favorites: this.$store.state.favorites.properties,
            slickOptionsDesktop: {
                infinite: true,
                //autoplay: (this.$config.guiSettings.autoplaySpeedInSalabam != 0),
                autoplaySpeed: this.$config.guiSettings.autoplaySpeedInSalabam,
                //vertical: true,
                //verticalSwiping: true,
                slidesToShow: 3,
                prevArrow: '#slick-prev-inSalabam'+this._uid+'_d',
                nextArrow: '#slick-next-inSalabam'+this._uid+'_d',
                adaptiveHeight:true,
                responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                    slidesToShow: 2,
                    }
                },
                /*
                {
                    breakpoint: 1200,
                    settings: {
                    slidesToShow: 2,
                    }
                },
                
                {
                    // sotto i 1200 viene nascosto
                }
                */
                ]
            },
            slickOptionsMobile: {
                infinite: true,
                //autoplay: (this.$config.guiSettings.autoplaySpeedInSalabam != 0),
                autoplaySpeed: this.$config.guiSettings.autoplaySpeedInSalabam,
                //vertical: true,
                //verticalSwiping: true,
                slidesToShow: 4,
                prevArrow: '#slick-prev-inSalabam'+this._uid+'_m',
                nextArrow: '#slick-next-inSalabam'+this._uid+'_m',
                adaptiveHeight:true,
                responsive: [
                    /* 
                {
                    breakpoint: 1600,
                    settings: {
                    slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                    slidesToShow: 2,
                    }
                },
                */
                {
                    breakpoint: 993,
                    settings: {
                    //adaptiveHeight:false,
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    //variableWidth: true,
                    //vertical: false,
                    //verticalSwiping: false,
                    }
                }
                ]
            },
      }
    },
    mounted()
    {
        this.loading = false
    },
    methods:{
          handleInit(){
        //handleInit(event, slick) {
            /*
            const [ slickTrack ] = slick.$slideTrack
            slickTrack.childNodes.forEach(slickSlide => {
                let _this = this
                slickSlide.onclick = function(e){
                    let property_id = parseInt(e.target.closest('.property_pad').getAttribute('data-property_id'))
                    let property = _this.favorites.filter(function (property) {
                        return property.property_id == property_id
                    })[0]
                    _this.goToProperty(property)
                }
            })
            */
        },
        goToProperty(property)
        {
            User.goToProperty(property, 'favorites')
        },
        searchLite()
        {
            let properties_ids = []

            this.favorites.forEach(favorite => {
                properties_ids.push(favorite.property.propertyId)
            })

            let searchParameters = {
                latitude: this.$store.state.geo.latitude,
                longitude: this.$store.state.geo.longitude,
                label: 'I tuoi preferiti',
                checkin: this.$store.state.dates.checkin,
                checkout: this.$store.state.dates.checkout,
                adults: this.$store.state.occupancy.adults,
                children: this.$store.state.occupancy.children,
                properties_ids: properties_ids
            }

            User.searchLite(searchParameters, 'favorites')
        },
    },
}
</script>

<style lang="scss">
    .favorites-slider
    {
        .sliderHeading
        {
            &--icon
            {
                color:$liteRed;
            }
        }
        
        .slick .infoPad
        {
            .abstract,
            .title
            {
                color:$white !important;
            }
            .background
            {
                background:$liteRed !important;
            }
        }
    }
</style>